.dcl-fixed-header,
#c-content,
.leftcontent,
footer,
.last-updated,
.lcs_slide_out,
#s-lib-bc,
#s-lg-guide-header-search,
.s-lg-tabs-side,
#s-lib-footer-public,
#s-la-public-skiplink {
  display: none !important;
}

a[href]::after {
  content: none !important;
}

#b-content,
.rightcontent {
  position: static !important;
  left: 0 !important;
  float: none !important;
  width: 100% !important;
}

p.selectionsTitle {
  page-break-inside: avoid;
}

.b4 {
  border: 1px solid #ccc;
  background-color: #fff !important;
}